import SVGInject from '@iconfu/svg-inject';

const SvgInjectPlugin = {
  install(app) {
    SVGInject.setOptions({
      onFail: function(img, svg) {
        img.classList.remove('svg-inject');
      }
    });

    // Create a global method to inject SVGs
    app.config.globalProperties.$injectSVGs = () => {
      SVGInject(document.querySelectorAll('img.svg-inject'), {
        useCache: true
      });
    };
  }
};

export default SvgInjectPlugin;