import { createRouter, createWebHistory } from 'vue-router'
import { authModule } from "@/stores/auth.js";
import { generalModule } from "@/stores/general.js";
import * as jose from 'jose'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'authLayout', requiresAuth: false },
      component: () => import('../views/login.vue'),
      beforeEnter: (to, from, next) => {
        const token = localStorage.getItem('token');
        if (token) {
          const decoded = jose.decodeJwt(token);
          if (decoded.exp < Date.now() / 1000) {
            localStorage.removeItem('token');
            localStorage.removeItem('usr');
            if (to.path !== '/login') {
              next({ path: '/login' });
            } else {
              next();
            }
          } else {
            // If the token is valid and user is trying to access the login page, redirect to /dashboard
            if (to.path === '/login') {
              next({ path: '/dashboard' });
            } else {
              next(); // Allow access to other routes
            }
          }
        } else {
          // If there is no token, allow access to the login page
          if (to.path === '/login') {
            next(); // Stay on the login page
          } else {
            next({ path: '/login' }); // Redirect to login for other routes
          }
        }
      }
    },
    {
      path: '/',
      name: 'Landing',
      meta: { layout: 'defaultLayout', requiresAuth: false },
      component: () => import('../views/index.vue')
    },
    {
      path: '/register',
      name: 'Register',
      meta: { layout: 'authLayout', requiresAuth: false },
      component: () => import('../views/register.vue')
    },
    {
      path: '/forget',
      name: 'Forget',
      meta: { layout: 'authLayout', requiresAuth: false },
      component: () => import('../views/forget.vue')
    },
    {
      path: '/set-password',
      name: 'Set Password',
      meta: { layout: 'authLayout', requiresAuth: false },
      component: () => import('../views/set-password.vue')
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: () => import('../views/dashboard.vue')
    },
    {
      path: '/account',
      name: 'My Account',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: () => import('../views/account.vue')
    },
    {
      path: '/packages',
      name: 'Packages',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: () => import('../views/packages/index.vue')
    },
    {
      path: '/packages/:id',
      name: 'Single Package',
      meta: { layout: 'defaultLayout', requiresAuth: true },
      component: () => import('../views/packages/id.vue')
    },

    //
    // {
    //   path: '/error/500',
    //   name: 'Error',
    //   meta: { layout: 'authLayout', requiresAuth: false },
    //   component: () => import('../views/error/500.vue')
    // },
    // {
    //   path: '/:pathMatch(.*)*',
    //   name: 'Error',
    //   meta: { layout: 'defaultLayout', requiresAuth: false },
    //   component: () => import('../views/error/404.vue')
    // }
  ]
})

router.beforeEach((to, from, next) => {
  const generalStore = generalModule()
  try {
    const authStore = authModule()

    let tips = document.querySelectorAll('.tooltip')
    let pops = document.querySelectorAll('.popover')
    if (tips.length > 0) {
      tips.forEach((tooltip) => {
        tooltip.remove()
      });
    }
    if (pops.length > 0) {
      pops.forEach((popover) => {
        popover.remove()
      });
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!localStorage.getItem('token')) {
        next({ path: '/login' })
      } else if (authStore.isLoggedIn) {
        next()
      } else {
        let token = localStorage.getItem('token')
        let decoded = jose.decodeJwt(token)

        if (decoded.exp < Date.now() / 1000) {
          localStorage.removeItem('token')
          next({ path: '/login' })
        } else {
          next()
        }
      }
    } else {
      next()
    }
  } catch (e) {
    generalStore.setError(true)
    return next()
  }
})

export default router
