<script>
  import { authModule } from "@/stores/auth.js";
  import { mapStores } from 'pinia'
  import { Offcanvas } from 'bootstrap';
  import Headhesive from 'headhesive';
  import smoothscroll from 'smoothscroll-polyfill';
  smoothscroll.polyfill();

  export default {
    name: "Cranium",
    mounted() {
      this.initSmooth();
      this.stickyHead();
      this.initOffCanvas();
    },
    computed: {
      ...mapStores(authModule)
    },
    methods: {
      initSmooth() {
        const links = document.querySelectorAll(".scroll");
        for(const link of links) {
          link.addEventListener("click", clickHandler);
        }
        function clickHandler(e) {
          e.preventDefault();
          this.blur();
          const href = this.getAttribute("href");
          const offsetTop = document.querySelector(href).offsetTop;

          // window.scroll({
          //   top: offsetTop,
          //   behavior: "smooth"
          // });
          scroll({
            top: offsetTop,
            behavior: "smooth"
          });
        }
        },
      stickyHead() {
        let navbar = document.querySelector(".navbar");
        if (navbar == null) return;
        let options = {
          offset: 350,
          offsetSide: 'top',
          classes: {
            clone: 'navbar-clone fixed',
            stick: 'navbar-stick',
            unstick: 'navbar-unstick',
          },
          onStick: function() {
            var navbarClonedClass = this.clonedElem.classList;
            if (navbarClonedClass.contains('transparent') && navbarClonedClass.contains('navbar-dark')) {
              this.clonedElem.className = this.clonedElem.className.replace("navbar-dark","navbar-light");
            }
          }
        };
        new Headhesive('.navbar', options);
      },
      initOffCanvas() {
        var navbar = document.querySelector(".navbar");
        if (navbar == null) return;
        const navOffCanvasBtn = document.querySelectorAll(".offcanvas-nav-btn");
        const navOffCanvas = document.querySelector('.navbar:not(.navbar-clone) .offcanvas-nav');
        const bsOffCanvas = new Offcanvas(navOffCanvas, {scroll: true});
        const scrollLink = document.querySelectorAll('.onepage .navbar li a.scroll');
        const searchOffcanvas = document.getElementById('offcanvas-search');
        navOffCanvasBtn.forEach(e => {
          e.addEventListener('click', event => {
            bsOffCanvas.show();
          })
        });
        scrollLink.forEach(e => {
          e.addEventListener('click', event => {
            bsOffCanvas.hide();
          })
        });
        if(searchOffcanvas != null) {
          searchOffcanvas.addEventListener('shown.bs.offcanvas', function () {
            document.getElementById("search-form").focus();
          });
        }
      }
    }
}
</script>

<template>
  <header class="wrapper bg-soft-primary">
    <nav class="navbar navbar-expand-lg center-nav transparent navbar-light">
      <div class="container flex-lg-row flex-nowrap align-items-center">
        <div class="navbar-brand w-100">
          <a href="/">
            <img src="/farin-to-yaad.png" srcset="" alt="" width="80" />
          </a>
        </div>
        <div class="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
          <div class="offcanvas-header d-lg-none">
            <h3 class="text-white fs-30 mb-0">FTY</h3>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body ms-lg-auto d-flex flex-column h-100">
            <ul v-if="!authStore.isLoggedIn" class="navbar-nav">
              <li class="nav-item"><a class="nav-link scroll" href="#hero">Home</a></li>
              <li class="nav-item"><a class="nav-link scroll" href="#about">About</a></li>
              <li class="nav-item"><a class="nav-link scroll" href="#works">How It Works</a></li>
              <li class="nav-item"><a class="nav-link scroll" href="#rates">Rates</a></li>
            </ul>
            <ul v-else class="navbar-nav">
              <li class="nav-item"><router-link class="nav-link" to="/dashboard">Dashboard</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/packages">Packages</router-link></li>
              <li class="nav-item dropdown d-lg-none">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{ authStore.isLoggedIn ? authStore.userObj.firstname + ' ' + authStore.userObj.lastname : 'No User' }}</a>
                <ul class="dropdown-menu">
                  <router-link class="dropdown-item" to="/account" data-bs-toggle="dropdown">Account</router-link>
                </ul>
              </li>
            </ul>
            <!-- /.navbar-nav -->
            <div class="offcanvas-footer d-lg-none">
              <div>
                <a href="mailto:first.last@email.com" class="link-inverse">info@email.com</a>
                <br /> 00 (123) 456 78 90 <br />
                <nav class="nav social social-white mt-4">
                  <a href="#"><i class="uil uil-twitter"></i></a>
                  <a href="#"><i class="uil uil-facebook-f"></i></a>
                  <a href="#"><i class="uil uil-dribbble"></i></a>
                  <a href="#"><i class="uil uil-instagram"></i></a>
                  <a href="#"><i class="uil uil-youtube"></i></a>
                </nav>
                <!-- /.social -->
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-other w-100 d-flex ms-auto">
          <ul class="navbar-nav flex-row align-items-center ms-auto">
            <li v-if="!authStore.isLoggedIn" class="nav-item d-none d-md-block">
              <router-link to="/login" class="btn btn-sm btn-primary rounded">Log In</router-link>
            </li>
            <li v-else class="nav-item dropdown d-none d-lg-block">
              <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{ authStore.isLoggedIn ? authStore.userObj.firstname + ' ' + authStore.userObj.lastname : 'No User' }}</a>
              <ul class="dropdown-menu">
                <li class="nav-item"><a class="dropdown-item" href="/account"><i class="uil uil-user"></i> Account</a></li>
                <li class="nav-item"><a class="dropdown-item" href="javascript:void(0)" @click="authStore.logOut"><i class="uil uil-sign-out-alt"></i> Logout</a></li>
              </ul>
            </li>
            <li class="nav-item d-lg-none">
              <button class="hamburger offcanvas-nav-btn"><span></span></button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped>

</style>