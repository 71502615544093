import { defineStore } from "pinia";

export const generalModule = defineStore('general',{
  state: () => ({
    communication: {},
    us_warehouse_address: {},
    rates: [],
    _error: false
  }),
  actions: {
    setSettings(payload) {
      this.communication = payload.settings.communication;
      this.us_warehouse_address = payload.settings.us_warehouse_address;
      this.rates = payload.rates;
    },
    setError(payload) {
      this._error = !!payload
    }
  },
  getters: {
    Rates: (state) => {
      return state.rates;
    },
    Communication: (state) => {
      return state.communication;
    },
    WarehouseAddress: (state) => {
      return state.us_warehouse_address;
    },
    error: (state) => {
      return state._error;
    }
  }
})