<script setup>
  import { onMounted } from "vue";
  import { RouterView } from 'vue-router'
  import Cranium from "../components/static/cranium.vue";
  import Feet from "@/components/static/feet.vue";
  import modules from "@/assets/js/modules.js";

  onMounted(() => {
    modules.init()
  })
</script>

<template>
  <div class="content-wrapper">
    <Cranium />
    <RouterView />
  </div>
  <feet />
  <div class="progress-wrap">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</template>

<style scoped>

</style>
