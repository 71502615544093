<script>
export default {
  name: "feet"
}
</script>

<template>
  <footer class="bg-white">
    <div class="container pt-8 pt-md-5 pb-7">
      <div class="row gx-lg-0 gy-6" v-if="$route.path === '/'">
        <div class="col-lg-4 mt-0">
          <div class="widget">
            <img class="mb-4" src="/farin-to-yaad.png" srcset="/farin-to-yaad.png" alt="" width="90" />
            <p class="lead mb-0">Join thousands of satisfied customers who trust us for fast, reliable shipping. Use our services today and take your business to new heights.</p>
          </div>
          <!-- /.widget -->
        </div>
        <!-- /column -->
        <div class="col-lg-3 offset-lg-2">
          <div class="widget">
            <div class="d-flex flex-row">
              <div>
                <div class="icon text-primary fs-28 me-4 mt-n1"> <i class="uil uil-phone-volume"></i> </div>
              </div>
              <div>
                <h5 class="mb-1">Phone</h5>
                <p class="mb-0">00 (123) 456 78 90 <br />00 (987) 654 32 10</p>
              </div>
            </div>
            <!--/div -->
          </div>
          <!-- /.widget -->
        </div>
        <!-- /column -->
        <div class="col-lg-3">
          <div class="widget">
            <div class="d-flex flex-row">
              <div>
                <div class="icon text-primary fs-28 me-4 mt-n1"> <i class="uil uil-location-pin-alt"></i> </div>
              </div>
              <div class="align-self-start justify-content-start">
                <h5 class="mb-1">Address</h5>
                <address>Moonshine St. 14/05 Light City, London, United Kingdom</address>
              </div>
            </div>
            <!--/div -->
          </div>
          <!-- /.widget -->
        </div>
        <!-- /column -->
      </div>
      <!--/.row -->
      <hr class="mt-13 mt-md-14 mb-7" />
      <div class="d-md-flex align-items-center justify-content-between">
        <p class="mb-2 mb-lg-0">© {{ new Date().getUTCFullYear() }} Sandbox. All rights reserved.</p>
        <nav class="nav social social-muted mb-0 text-md-end">
          <a href="#"><i class="uil uil-twitter"></i></a>
          <a href="#"><i class="uil uil-facebook-f"></i></a>
          <a href="#"><i class="uil uil-dribbble"></i></a>
          <a href="#"><i class="uil uil-instagram"></i></a>
          <a href="#"><i class="uil uil-youtube"></i></a>
        </nav>
        <!-- /.social -->
      </div>
    </div>
    <!-- /.container -->
  </footer>
</template>

<style scoped>

</style>